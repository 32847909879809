var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main-boards__content-IscBoards" },
    [
      _c("div", { staticClass: "main-boards__content_grid" }, [
        _c(
          "div",
          { staticClass: "main-boards__content_grid_item bg-white" },
          [
            _c(
              "v-row",
              _vm._l(_vm.ISCBoards, function(board) {
                return _c(
                  "v-col",
                  { key: board.id, attrs: { lg: "3", md: "4", sm: "6" } },
                  [
                    _c(
                      "v-card",
                      {
                        staticClass:
                          "grid-item__content position-relative elevation-2 pt-4"
                      },
                      [
                        _c("div", {
                          staticClass:
                            "grid-item__content--ribbon position-absolute rounded-0"
                        }),
                        _c(
                          "div",
                          { staticClass: "grid-item__content--title mb-5" },
                          [
                            _c(
                              "h2",
                              {
                                staticClass:
                                  "\n                  text-capitalize text-center\n                  font15pt\n                  titleColor\n                  font-weight-bold\n                "
                              },
                              [_vm._v(" " + _vm._s(board.name) + " ")]
                            )
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "\n                grid-item__content--values\n                text-center\n                d-flex\n                flex-column\n                mt-5\n                pt-3\n              "
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "\n                  grid-item__content--values--days\n                  font10pt\n                  titleColor\n                  text-capitalize\n                "
                              },
                              [_vm._v("last 15 days")]
                            ),
                            _c(
                              "span",
                              {
                                staticClass:
                                  "\n                  grid-item__content--values--count\n                  text-capitalize\n                  font37pt\n                  titleColor\n                  font-weight-bold\n                "
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.convertNumberTokFormat(
                                      board.dashboard_metrics.count_samples
                                    )
                                  )
                                )
                              ]
                            ),
                            _c(
                              "span",
                              {
                                staticClass:
                                  "\n                  grid-item__content--values--type\n                  text-capitalize\n                  font12pt\n                  titleColor\n                "
                              },
                              [_vm._v("Samples")]
                            )
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "grid-item__content--graph px-2",
                            staticStyle: { "min-height": "100px" }
                          },
                          [
                            _c("apexchart", {
                              attrs: {
                                type: "area",
                                height: "100",
                                options: _vm.chartOptionsIscBoards,
                                series: [
                                  {
                                    name: "samples",
                                    data: board.dashboard_metrics.histogram
                                  }
                                ]
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "grid-item__content--buttom pa-1" },
                          [
                            _c(
                              "button",
                              {
                                staticClass: "button-overview bgColorBase",
                                on: {
                                  click: function($event) {
                                    return _vm.toOverview(board.id)
                                  }
                                }
                              },
                              [
                                _c("v-icon", { attrs: { size: "14" } }, [
                                  _vm._v("fas fa-eye")
                                ])
                              ],
                              1
                            )
                          ]
                        )
                      ]
                    )
                  ],
                  1
                )
              }),
              1
            )
          ],
          1
        )
      ]),
      _c(
        "v-overlay",
        { attrs: { value: _vm.loading } },
        [
          _c("v-progress-circular", {
            attrs: { indeterminate: "", size: "64" }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }