var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("v-row", { staticStyle: { "padding-left": "5rem" } }, [
        _c(
          "div",
          { staticClass: "w-100", staticStyle: { "margin-top": "150px" } },
          [
            _c("v-col", { staticClass: "text-right", attrs: { cols: "12" } }, [
              !_vm.yourBoard
                ? _c("div", { staticClass: "d-inline-flex selectBox" }, [
                    _c(
                      "div",
                      { staticClass: "d-flex justify-center align-center" },
                      [
                        _vm.country == "Peru"
                          ? _c("v-img", {
                              attrs: {
                                width: "35",
                                height: "35",
                                src: _vm.peruImage
                              }
                            })
                          : _vm._e(),
                        _vm.country == "Chile"
                          ? _c("v-img", {
                              attrs: {
                                width: "35",
                                height: "35",
                                src: _vm.chileImage
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticStyle: { padding: "0px 12px" } },
                      [
                        _c("v-select", {
                          staticClass: "ml-3",
                          attrs: {
                            items: _vm.countries,
                            "item-text": "name",
                            "item-value": "id",
                            "return-object": ""
                          },
                          on: { change: _vm.changeCountry },
                          model: {
                            value: _vm.defaultSelect,
                            callback: function($$v) {
                              _vm.defaultSelect = $$v
                            },
                            expression: "defaultSelect"
                          }
                        })
                      ],
                      1
                    )
                  ])
                : _vm._e(),
              _c(
                "div",
                { staticStyle: { padding: "0px 12px" } },
                [
                  _vm.yourBoard
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "mb-3",
                          attrs: { color: "primary" },
                          on: {
                            click: function($event) {
                              _vm.modalOpen = true
                            }
                          }
                        },
                        [_vm._v("Create boards")]
                      )
                    : _vm._e()
                ],
                1
              )
            ]),
            _c("v-col", { attrs: { cols: "12" } }, [
              _c(
                "div",
                { staticClass: "main-boards" },
                [
                  _c("router-view", {
                    attrs: {
                      countryId: _vm.countryId,
                      contextCreated: _vm.contextCreated
                    },
                    on: { updateContextCreated: _vm.updateContextCreated }
                  })
                ],
                1
              )
            ])
          ],
          1
        )
      ]),
      _vm.modalOpen
        ? _c("CreateContext", { on: { close: _vm.handleCreateModalClose } })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }