var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main-boards__content-yourBoards" },
    [
      _c("div", { staticClass: "main-boards__content_grid" }, [
        _c(
          "div",
          { staticClass: "main-boards__content_grid_item bg-white" },
          [
            _c(
              "v-row",
              _vm._l(_vm.accountBoards, function(board) {
                return _c(
                  "v-col",
                  { key: board.id, attrs: { lg: "3", md: "4", sm: "6" } },
                  [
                    _c(
                      "v-card",
                      [
                        _c(
                          "v-card-title",
                          { staticClass: "text-center d-block font15pt mb-2" },
                          [
                            _c("span", { staticClass: "font-weight-bold" }, [
                              _vm._v(_vm._s(board.name))
                            ]),
                            (board.tw_key_count.count !== 0 &&
                              board.tw_key_count.active == false) ||
                            (board.fb_key_count.count !== 0 &&
                              board.fb_key_count.active == false) ||
                            (board.ig_key_count.count !== 0 &&
                              board.ig_key_count.active == false) ||
                            (board.lk_key_count.count !== 0 &&
                              board.lk_key_count.active == false) ||
                            (board.nws_key_count.count !== 0 &&
                              board.nws_key_count.active == false)
                              ? _c(
                                  "span",
                                  { staticClass: "ml-1" },
                                  [
                                    _c("tooltipComponent", {
                                      attrs: { tooltipText: _vm.tooltip }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e()
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "\n                d-flex\n                justify-center\n                align-center\n                main-boards__content-yourBoards__socialicons\n              "
                          },
                          [
                            board.tw_key_count.count !== 0
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "position-relative",
                                    class: {
                                      tw_color:
                                        board.tw_key_count.active == true
                                    }
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "\n                    position-absolute\n                    alerticon\n                    text-center\n                    font-weight-bold\n                  "
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(board.tw_key_count.count) +
                                            " "
                                        )
                                      ]
                                    ),
                                    _c("v-icon", { attrs: { size: "24" } }, [
                                      _vm._v("fab fa-twitter")
                                    ])
                                  ],
                                  1
                                )
                              : _vm._e(),
                            board.fb_key_count.count !== 0
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "position-relative ml-3",
                                    class: {
                                      fb_color:
                                        board.fb_key_count.active == true
                                    }
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "\n                    position-absolute\n                    alerticon\n                    text-center\n                    font-weight-bold\n                  "
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(board.fb_key_count.count) +
                                            " "
                                        )
                                      ]
                                    ),
                                    _c("v-icon", { attrs: { size: "24" } }, [
                                      _vm._v("fab fa-facebook-square")
                                    ])
                                  ],
                                  1
                                )
                              : _vm._e(),
                            board.ig_key_count.count !== 0
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "position-relative ml-3",
                                    class: {
                                      ig_color:
                                        board.ig_key_count.active == true
                                    }
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "\n                    position-absolute\n                    alerticon\n                    text-center\n                    font-weight-bold\n                  "
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(board.ig_key_count.count) +
                                            " "
                                        )
                                      ]
                                    ),
                                    _c("v-icon", { attrs: { size: "24" } }, [
                                      _vm._v("fab fa-instagram")
                                    ])
                                  ],
                                  1
                                )
                              : _vm._e(),
                            board.lk_key_count.count !== 0
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "position-relative ml-3",
                                    class: {
                                      lk_color:
                                        board.lk_key_count.active == true
                                    }
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "\n                    position-absolute\n                    alerticon\n                    text-center\n                    font-weight-bold\n                  "
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(board.lk_key_count.count) +
                                            " "
                                        )
                                      ]
                                    ),
                                    _c("v-icon", { attrs: { size: "24" } }, [
                                      _vm._v("fab fa-linkedin")
                                    ])
                                  ],
                                  1
                                )
                              : _vm._e(),
                            board.nws_key_count.count !== 0
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "position-relative ml-3",
                                    class: {
                                      nws_color:
                                        board.nws_key_count.active == true
                                    }
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "\n                    position-absolute\n                    alerticon\n                    text-center\n                    font-weight-bold\n                  "
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(board.nws_key_count.count) +
                                            " "
                                        )
                                      ]
                                    ),
                                    _c("v-icon", { attrs: { size: "24" } }, [
                                      _vm._v("fa-solid fa-newspaper")
                                    ])
                                  ],
                                  1
                                )
                              : _vm._e()
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "main-boards__content-yourBoards__gauge"
                          },
                          [
                            _c("vue-gauge", {
                              staticClass: "text-center",
                              attrs: {
                                if: "board.dashboard_metrics.sentiment > 0",
                                refid: "id_" + board.id,
                                options: board.gaugeOptions
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "main-boards__content-yourBoards__info pl-1 pr-1"
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "\n                  main-boards__content-yourBoards__info-value\n                  text-center\n                  mb-3\n                "
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "font15pt titleColor font-weight-light"
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          Math.round(
                                            _vm.getMaxSentiment(
                                              board.dashboard_metrics.sentiment
                                            )
                                          )
                                        ) +
                                        "% "
                                    )
                                  ]
                                )
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "\n                  d-flex\n                  main-boards__content-yourBoards__info__boxsentiment\n                  pl-3\n                  pr-3\n                "
                              },
                              [
                                _c("div", {
                                  staticClass:
                                    "\n                    pa-0\n                    bgColorpositive\n                    main-boards__content-yourBoards__info__boxsentiment-item\n                  ",
                                  style: {
                                    width:
                                      _vm.convertDecimalToPercent(
                                        board.dashboard_metrics.sentiment.pos
                                      ) + "% !important"
                                  }
                                }),
                                _c("div", {
                                  staticClass:
                                    "\n                    pa-0\n                    bgColorneutral\n                    main-boards__content-yourBoards__info__boxsentiment-item\n                  ",
                                  style: {
                                    width:
                                      _vm.convertDecimalToPercent(
                                        board.dashboard_metrics.sentiment.neu
                                      ) + "% !important"
                                  }
                                }),
                                _c("div", {
                                  staticClass:
                                    "\n                    pa-0\n                    bgColornegative\n                    main-boards__content-yourBoards__info__boxsentiment-item\n                  ",
                                  style: {
                                    width:
                                      _vm.convertDecimalToPercent(
                                        board.dashboard_metrics.sentiment.neg
                                      ) + "% !important"
                                  }
                                })
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "\n                  d-flex\n                  main-boards__content-yourBoards__info__boxvalues\n                  pl-3\n                  pr-3\n                "
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "col pt-1 pb-2 text-center" },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "font10pt leyendColor" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              Math.round(
                                                _vm.convertDecimalToPercent(
                                                  board.dashboard_metrics
                                                    .sentiment.pos
                                                )
                                              )
                                            ) +
                                            "% "
                                        )
                                      ]
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "col pt-1 pb-2 text-center" },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "font10pt leyendColor" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              Math.round(
                                                _vm.convertDecimalToPercent(
                                                  board.dashboard_metrics
                                                    .sentiment.neu
                                                )
                                              )
                                            ) +
                                            "% "
                                        )
                                      ]
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "col pt-1 pb-2 text-center" },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "font10pt leyendColor" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              Math.round(
                                                _vm.convertDecimalToPercent(
                                                  board.dashboard_metrics
                                                    .sentiment.neg
                                                )
                                              )
                                            ) +
                                            "% "
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "\n                  grid-item__content--values\n                  text-center\n                  d-flex\n                  flex-column\n                  pt-3\n                  pb-0\n                  bgGrapg\n                "
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "\n                    grid-item__content--values--days\n                    font10pt\n                    text-capitalize\n                    white--text\n                    font-weight-bold\n                  "
                                  },
                                  [_vm._v("last 15 days")]
                                ),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "\n                    grid-item__content--values--count\n                    text-capitalize\n                    font37pt\n                    font-weight-bold\n                    white--text\n                    font-weight-bold\n                  "
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.convertNumberTokFormat(
                                            board.dashboard_metrics
                                              .count_samples
                                          )
                                        )
                                    )
                                  ]
                                ),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "\n                    grid-item__content--values--type\n                    text-capitalize\n                    font15pt\n                    white--text\n                    font-weight-bold\n                  "
                                  },
                                  [_vm._v("Samples")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "grid-item__content--values__graph px-2",
                                    staticStyle: { "min-height": "100px" }
                                  },
                                  [
                                    _vm.convertNumberTokFormat(
                                      board.dashboard_metrics.count_samples
                                    ) !== 0
                                      ? _c("apexchart", {
                                          attrs: {
                                            type: "area",
                                            height: "100",
                                            options: _vm.chartOptions,
                                            series: [
                                              {
                                                name: "samples",
                                                data:
                                                  board.dashboard_metrics
                                                    .histogram
                                              }
                                            ]
                                          }
                                        })
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ]
                            )
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex main-boards__content-yourBoards__buttoms"
                          },
                          [
                            _c("div", { staticClass: "col-4 pa-1" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "button-overview bgColorBase",
                                  on: {
                                    click: function($event) {
                                      return _vm.toOverview(board.id)
                                    }
                                  }
                                },
                                [
                                  _c("v-icon", { attrs: { size: "18" } }, [
                                    _vm._v("fas fa-eye")
                                  ])
                                ],
                                1
                              )
                            ]),
                            _c("div", { staticClass: "col-4 pa-1" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "button-overview bgColorBase",
                                  on: {
                                    click: function($event) {
                                      return _vm.updateContextModal(board.id)
                                    }
                                  }
                                },
                                [
                                  _c("v-icon", { attrs: { size: "18" } }, [
                                    _vm._v("fa fa-edit")
                                  ])
                                ],
                                1
                              )
                            ]),
                            _c("div", { staticClass: "col-4 pa-1" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "button-overview bgColorBase",
                                  on: {
                                    click: function($event) {
                                      _vm.contextToDelete = board.id
                                    }
                                  }
                                },
                                [
                                  _c("v-icon", { attrs: { size: "18" } }, [
                                    _vm._v("fa fa-trash")
                                  ])
                                ],
                                1
                              )
                            ])
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              }),
              1
            )
          ],
          1
        )
      ]),
      _vm.updateContextModalOpen
        ? _c("UpdateContext", {
            attrs: { contextData: _vm.contextData },
            on: {
              close: function($event) {
                _vm.updateContextModalOpen = false
              },
              refreshData: _vm.refreshData
            }
          })
        : _vm._e(),
      _c(
        "v-overlay",
        { attrs: { value: _vm.overlay } },
        [
          _c("v-progress-circular", {
            attrs: { indeterminate: "", size: "64" }
          })
        ],
        1
      ),
      _vm.contextToDelete
        ? _c("div", { staticClass: "modal-mask" }, [
            _c("div", { staticClass: "modal-wrapper" }, [
              _c(
                "div",
                { staticClass: "modal-container w-50" },
                [
                  _c(
                    "v-row",
                    [
                      _c("v-col", [
                        _c(
                          "h1",
                          {
                            staticClass: "font18pt titleColor font-weight-bold"
                          },
                          [
                            _vm._v(
                              ' Are you sure you want to delete "' +
                                _vm._s(
                                  _vm.accountBoards.find(function(board) {
                                    return board.id == _vm.contextToDelete
                                  }).name
                                ) +
                                '"? '
                            )
                          ]
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c("v-col", { staticClass: "text-right" }, [
                        _c(
                          "button",
                          {
                            staticClass:
                              "bgColorPrincipal pt-2 pb-2 pl-5 pr-5 ml-3 rounded",
                            attrs: { disabled: _vm.loadingDelete },
                            on: {
                              click: function($event) {
                                _vm.contextToDelete = null
                              }
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "mr-1 font13pt white--text" },
                              [_vm._v("No")]
                            )
                          ]
                        ),
                        _c(
                          "button",
                          {
                            staticClass:
                              "bgColorPrincipal pt-2 pb-2 pl-5 pr-5 ml-3 rounded",
                            attrs: { disabled: _vm.loadingDelete },
                            on: {
                              click: function($event) {
                                return _vm.deleteContext()
                              }
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "mr-1 font13pt white--text" },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.loadingDelete ? "Deleting..." : "Yes"
                                  )
                                )
                              ]
                            )
                          ]
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ])
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }