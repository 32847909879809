var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "modal" } }, [
    _c("div", { staticClass: "modal-mask" }, [
      _c("div", { staticClass: "modal-wrapper" }, [
        _c("div", { staticClass: "modal-container" }, [
          _c(
            "div",
            { staticClass: "context-content" },
            [
              _c(
                "button",
                {
                  staticClass:
                    "bgColorPrincipal modal-icon-close px-2 py-1 mr-3 mt-3 rounded",
                  on: {
                    click: function($event) {
                      return _vm.$emit("close")
                    }
                  }
                },
                [
                  _c("v-icon", { attrs: { color: "#fff", size: "16" } }, [
                    _vm._v(" fas fa-times ")
                  ])
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "d-flex justify-center" },
                [
                  _c(
                    "v-scroll-y-transition",
                    [
                      _vm.warningText
                        ? _c(
                            "v-alert",
                            {
                              staticStyle: {
                                position: "fixed",
                                "max-width": "20em"
                              },
                              attrs: {
                                type: "error",
                                dense: "",
                                transition: "fab-transition"
                              }
                            },
                            [_vm._v(" " + _vm._s(_vm.warningText) + " ")]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c("v-col", [
                    _c(
                      "h1",
                      { staticClass: "font18pt titleColor font-weight-bold" },
                      [_vm._v("Create context")]
                    )
                  ])
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "context-content__search",
                      attrs: { cols: "12" }
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.name,
                            expression: "name"
                          }
                        ],
                        staticClass:
                          "inputs pl-3 font13pt titleColor font-weight-bold",
                        attrs: {
                          maxlength: "40",
                          type: "text",
                          placeholder: "Context name"
                        },
                        domProps: { value: _vm.name },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.name = $event.target.value
                          }
                        }
                      })
                    ]
                  )
                ],
                1
              ),
              _c("v-row", [
                _c(
                  "div",
                  { staticClass: "component-content__tabs col" },
                  [
                    _c(
                      "v-tabs",
                      {
                        staticClass: "component-content__tabs-menu",
                        attrs: {
                          centered: "",
                          "slider-color": "#00A5FF",
                          "background-color": "#f3f4f7",
                          height: "51",
                          grow: ""
                        },
                        model: {
                          value: _vm.model,
                          callback: function($$v) {
                            _vm.model = $$v
                          },
                          expression: "model"
                        }
                      },
                      [
                        _c("v-tab", [
                          _c(
                            "span",
                            {
                              staticClass:
                                "font13pt titleColor font-weight-bold text-capitalize mr-1"
                            },
                            [_vm._v("Twitter")]
                          ),
                          _c(
                            "span",
                            [
                              _c(
                                "v-icon",
                                { attrs: { size: "13", color: "#1da1f2" } },
                                [_vm._v("fab fa-twitter")]
                              )
                            ],
                            1
                          )
                        ]),
                        _c("v-tab", [
                          _c(
                            "span",
                            {
                              staticClass:
                                "font13pt titleColor font-weight-bold text-capitalize mr-1"
                            },
                            [_vm._v("Facebook")]
                          ),
                          _c(
                            "span",
                            [
                              _c(
                                "v-icon",
                                { attrs: { size: "13", color: "#1877f2" } },
                                [_vm._v("fab fa-facebook")]
                              )
                            ],
                            1
                          )
                        ]),
                        _c("v-tab", [
                          _c(
                            "span",
                            {
                              staticClass:
                                "font13pt titleColor font-weight-bold text-capitalize mr-1"
                            },
                            [_vm._v("Instagram")]
                          ),
                          _c(
                            "span",
                            [
                              _c(
                                "v-icon",
                                { attrs: { size: "13", color: "#ff69a1" } },
                                [_vm._v("fab fa-instagram")]
                              )
                            ],
                            1
                          )
                        ]),
                        _c("v-tab", [
                          _c(
                            "span",
                            {
                              staticClass:
                                "font13pt titleColor font-weight-bold text-capitalize mr-1"
                            },
                            [_vm._v("Linkedin")]
                          ),
                          _c(
                            "span",
                            [
                              _c(
                                "v-icon",
                                { attrs: { size: "13", color: "#0a66c2" } },
                                [_vm._v("fab fa-linkedin")]
                              )
                            ],
                            1
                          )
                        ]),
                        _c("v-tab", [
                          _c(
                            "span",
                            {
                              staticClass:
                                "font13pt titleColor font-weight-bold text-capitalize mr-1"
                            },
                            [_vm._v("Newspaper")]
                          ),
                          _c(
                            "span",
                            [
                              _c(
                                "v-icon",
                                { attrs: { size: "13", color: "#444444" } },
                                [_vm._v("fa-solid fa-newspaper")]
                              )
                            ],
                            1
                          )
                        ])
                      ],
                      1
                    ),
                    _c(
                      "v-tabs-items",
                      {
                        staticClass: "component-content__tabs__info pb-0",
                        model: {
                          value: _vm.model,
                          callback: function($$v) {
                            _vm.model = $$v
                          },
                          expression: "model"
                        }
                      },
                      [
                        _c(
                          "v-tab-item",
                          {
                            attrs: {
                              transition: "none",
                              "reverse-transition": "fade-transition"
                            }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "base_content d-flex justify-center align-center"
                              },
                              [
                                _c("CreateTwitterDrivers", {
                                  attrs: {
                                    allDriversToAdd: _vm.allDriversToAdd.filter(
                                      function(d) {
                                        return d.type === "tw"
                                      }
                                    ),
                                    allIscDriversToAdd: _vm.allIscDriversToAdd.filter(
                                      function(d) {
                                        return d.type === "tw"
                                      }
                                    )
                                  },
                                  on: {
                                    driverToAdd: _vm.driverToAdd,
                                    deleteDriverToAdd: _vm.deleteDriverToAdd,
                                    iscDriverToAdd: _vm.iscDriverToAdd,
                                    deleteIscDriverToAdd:
                                      _vm.deleteIscDriverToAdd,
                                    driverLoading: _vm.driverLoading,
                                    setWarningMessage: _vm.setWarningMessage
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        ),
                        _c(
                          "v-tab-item",
                          {
                            attrs: {
                              transition: "fade-transition",
                              "reverse-transition": "fade-transition"
                            }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "base_content d-flex justify-center align-center"
                              },
                              [
                                _c("CreateFacebookDrivers", {
                                  attrs: {
                                    allDriversToAdd: _vm.allDriversToAdd.filter(
                                      function(d) {
                                        return d.type === "fb"
                                      }
                                    ),
                                    allIscDriversToAdd: _vm.allIscDriversToAdd.filter(
                                      function(d) {
                                        return d.type === "fb"
                                      }
                                    )
                                  },
                                  on: {
                                    driverToAdd: _vm.driverToAdd,
                                    deleteDriverToAdd: _vm.deleteDriverToAdd,
                                    iscDriverToAdd: _vm.iscDriverToAdd,
                                    deleteIscDriverToAdd:
                                      _vm.deleteIscDriverToAdd,
                                    driverLoading: _vm.driverLoading,
                                    setWarningMessage: _vm.setWarningMessage
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        ),
                        _c(
                          "v-tab-item",
                          {
                            attrs: {
                              transition: "fade-transition",
                              "reverse-transition": "fade-transition"
                            }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "base_content d-flex justify-center align-center"
                              },
                              [
                                _c("CreateInstagramDrivers", {
                                  attrs: {
                                    allDriversToAdd: _vm.allDriversToAdd.filter(
                                      function(d) {
                                        return d.type === "ig"
                                      }
                                    ),
                                    allIscDriversToAdd: _vm.allIscDriversToAdd.filter(
                                      function(d) {
                                        return d.type === "ig"
                                      }
                                    )
                                  },
                                  on: {
                                    driverToAdd: _vm.driverToAdd,
                                    deleteDriverToAdd: _vm.deleteDriverToAdd,
                                    iscDriverToAdd: _vm.iscDriverToAdd,
                                    deleteIscDriverToAdd:
                                      _vm.deleteIscDriverToAdd,
                                    driverLoading: _vm.driverLoading,
                                    setWarningMessage: _vm.setWarningMessage
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        ),
                        _c(
                          "v-tab-item",
                          {
                            attrs: {
                              transition: "fade-transition",
                              "reverse-transition": "fade-transition"
                            }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "base_content d-flex justify-center align-center"
                              },
                              [
                                _c("CreateLinkedinDrivers", {
                                  attrs: {
                                    allDriversToAdd: _vm.allDriversToAdd.filter(
                                      function(d) {
                                        return d.type === "lk"
                                      }
                                    ),
                                    allIscDriversToAdd: _vm.allIscDriversToAdd.filter(
                                      function(d) {
                                        return d.type === "lk"
                                      }
                                    )
                                  },
                                  on: {
                                    driverToAdd: _vm.driverToAdd,
                                    deleteDriverToAdd: _vm.deleteDriverToAdd,
                                    iscDriverToAdd: _vm.iscDriverToAdd,
                                    deleteIscDriverToAdd:
                                      _vm.deleteIscDriverToAdd,
                                    driverLoading: _vm.driverLoading,
                                    setWarningMessage: _vm.setWarningMessage
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        ),
                        _c(
                          "v-tab-item",
                          {
                            attrs: {
                              transition: "fade-transition",
                              "reverse-transition": "fade-transition"
                            }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "base_content d-flex justify-center align-center"
                              },
                              [
                                _c("CreateNewsPaperDrivers", {
                                  attrs: {
                                    allDriversToAdd: _vm.allDriversToAdd.filter(
                                      function(d) {
                                        return d.type === "nws"
                                      }
                                    )
                                  },
                                  on: {
                                    driverToAdd: _vm.driverToAdd,
                                    deleteDriverToAdd: _vm.deleteDriverToAdd,
                                    driverLoading: _vm.driverLoading,
                                    setWarningMessage: _vm.setWarningMessage
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]),
              _c(
                "v-row",
                [
                  _c("v-col", { staticClass: "text-right" }, [
                    _c(
                      "button",
                      {
                        staticClass:
                          "buttonSave pt-2 pb-2 pl-5 pr-5 ml-3 rounded",
                        on: {
                          click: function($event) {
                            return _vm.saveContext()
                          }
                        }
                      },
                      [
                        _c("span", { staticClass: "mr-1 font13pt" }, [
                          _vm._v(_vm._s(_vm.loading ? "Saving..." : "Save"))
                        ]),
                        !_vm.loading
                          ? _c(
                              "v-icon",
                              { attrs: { color: "#fff", size: "13" } },
                              [_vm._v(" fas fa-save ")]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "prog-bar__createContext" },
            [
              _vm.loading || _vm.checkDriverLoading
                ? _c("v-progress-linear", { attrs: { indeterminate: "" } })
                : _vm._e()
            ],
            1
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }