var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "context-box", staticStyle: { width: "90%" } },
    [
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            { attrs: { sm: 12, md: 12, lg: 12 } },
            [
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c("v-col", { attrs: { cols: "12" } }, [
                    _c("span", { staticClass: "font12pt leyendColor" }, [
                      _vm._v("What:")
                    ])
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { sm: 6, md: 9, lg: 10 } },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "pr-1 pl-1 pt-1" },
                    [
                      _c("v-select", {
                        attrs: {
                          label: "Country",
                          disabled: _vm.allNewspapers.length == 0,
                          items: _vm.nwsCountries,
                          loading: _vm.allNewspapers.length == 0,
                          "hide-details": ""
                        },
                        on: {
                          change: function($event) {
                            return _vm.changeSelectPlace(_vm.nwsCountrySelected)
                          }
                        },
                        model: {
                          value: _vm.nwsCountrySelected,
                          callback: function($$v) {
                            _vm.nwsCountrySelected = $$v
                          },
                          expression: "nwsCountrySelected"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "pr-1 pl-1 pt-1" },
                    [
                      _c("v-select", {
                        attrs: {
                          label: "Newspaper",
                          disabled: _vm.nwsCountrySelected == "",
                          items: _vm.nwsNames,
                          loading: _vm.allNewspapers.length == 0,
                          "hide-details": ""
                        },
                        on: {
                          change: function($event) {
                            return _vm.changeSelectName(_vm.nwsNameSelected)
                          }
                        },
                        model: {
                          value: _vm.nwsNameSelected,
                          callback: function($$v) {
                            _vm.nwsNameSelected = $$v
                          },
                          expression: "nwsNameSelected"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "pr-1 pl-1 pt-1" },
                    [
                      _c("v-select", {
                        attrs: {
                          label: "Section",
                          disabled: _vm.nwsNameSelected == "",
                          items: _vm.nwsUrls,
                          loading: _vm.allNewspapers.length == 0,
                          "hide-details": ""
                        },
                        on: {
                          change: function($event) {
                            return _vm.changeSelectUrl(_vm.nwsUrlSelected)
                          }
                        },
                        model: {
                          value: _vm.nwsUrlSelected,
                          callback: function($$v) {
                            _vm.nwsUrlSelected = $$v
                          },
                          expression: "nwsUrlSelected"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c("v-col", { staticClass: "pr-1 pl-1 pt-1" }, [
                    _vm.warningText != ""
                      ? _c("div", { staticClass: "pt-1 textError ml-0" }, [
                          _c(
                            "span",
                            [
                              _c(
                                "v-icon",
                                { attrs: { size: "12", color: "red" } },
                                [_vm._v("fa fa-exclamation-circle")]
                              ),
                              _vm._v(" " + _vm._s(_vm.warningText))
                            ],
                            1
                          )
                        ])
                      : _vm._e()
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c("v-col", [
            _c(
              "div",
              { staticClass: "d-flex justify-end align-end" },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "mainBtn mx-2 mt-2",
                    attrs: {
                      depressed: "",
                      color: "bgColorPrincipal",
                      disabled: _vm.nwsToAdd === ""
                    },
                    on: {
                      click: function($event) {
                        return _vm.addNewDriver()
                      }
                    }
                  },
                  [_c("span", { staticClass: "white--text" }, [_vm._v("Add")])]
                )
              ],
              1
            )
          ]),
          _c(
            "v-row",
            { staticClass: "context-box__table" },
            [
              _c("v-col", [
                _c(
                  "div",
                  { staticClass: "context-box__table__header d-flex" },
                  [
                    _c("div", { staticClass: "col-12" }, [
                      _c("span", { staticClass: "font12pt leyendColor" }, [
                        _vm._v("Profile Page list")
                      ])
                    ])
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "bgColorBase context-box__table__body" },
                  [
                    _c("v-virtual-scroll", {
                      attrs: {
                        bench: 10,
                        items: _vm.allDriversToAdd,
                        "max-height": "168",
                        "item-height": "42"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c(
                                "v-row",
                                {
                                  style: {
                                    // backgroundColor: item.geo_dashboard ? '#fbfbfb' : 'inherit',
                                  }
                                },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "1", align: "end" } },
                                    [
                                      _c(
                                        "div",
                                        [
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: { top: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function(ref) {
                                                      var on = ref.on
                                                      var attrs = ref.attrs
                                                      return [
                                                        item.geo_dashboard
                                                          ? _c(
                                                              "v-icon",
                                                              _vm._g(
                                                                _vm._b(
                                                                  {
                                                                    attrs: {
                                                                      size:
                                                                        "14",
                                                                      color:
                                                                        "#a2acc4"
                                                                    }
                                                                  },
                                                                  "v-icon",
                                                                  attrs,
                                                                  false
                                                                ),
                                                                on
                                                              ),
                                                              [
                                                                _vm._v(
                                                                  "fas fa-globe"
                                                                )
                                                              ]
                                                            )
                                                          : _vm._e()
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            },
                                            [_c("span", [_vm._v("ISC Driver")])]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _c("v-col", { attrs: { cols: "5" } }, [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          overflow: "hidden",
                                          "white-space": "nowrap",
                                          "text-overflow": "ellipsis"
                                        }
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "font12pt titleColor ml-3"
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(item.newspaper_name) +
                                                " "
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ]),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "4", align: "start" } },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            overflow: "hidden",
                                            "white-space": "nowrap",
                                            "text-overflow": "ellipsis"
                                          }
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "font12pt leyendColor"
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    "" +
                                                      (item.page_url
                                                        .split("/")
                                                        .slice(-1) == ""
                                                        ? item.page_url
                                                            .split("/")
                                                            .slice(-2, -1)
                                                            .toString()
                                                        : item.page_url
                                                            .split("/")
                                                            .slice(-1)
                                                            .toString())
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "1", align: "start" } },
                                    [
                                      _c(
                                        "div",
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                size: "11",
                                                color: "#a2acc4"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.deleteDriver(item)
                                                }
                                              }
                                            },
                                            [_vm._v("fas fa-trash")]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "1", align: "start" } },
                                    [
                                      _c(
                                        "div",
                                        [
                                          item.active === true
                                            ? _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    size: "12",
                                                    color: "#00a5ff"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      item.active = false
                                                    }
                                                  }
                                                },
                                                [_vm._v("fas fa-eye")]
                                              )
                                            : _vm._e(),
                                          item.active === false
                                            ? _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    size: "12",
                                                    color: "#a2acc4"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      item.active = true
                                                    }
                                                  }
                                                },
                                                [_vm._v("fas fa-eye")]
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ])
                    })
                  ],
                  1
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }